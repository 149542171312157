import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type SEOProps = {
  description?: string
  lang?: string
  meta?: any
  keywords?: any
  title: string
}

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
}) => {
  const { 
    ogTitle,
    ogDescription,
    defaultMetaDescription,
    seoKeywords,
    site: {siteMetadata: {author}} 
  } = useStaticQuery(
    graphql`
        query FetchAllStrapiSeoData {
          ogTitle: strapiSeo(attribute: { eq: "og:title" }) {
              value
          }
          ogDescription: strapiSeo(attribute: { eq: "og:description" }) {
              value
          }
          defaultMetaDescription: strapiSeo(attribute: { eq: "description" }) {
              value
          }
          seoKeywords: strapiSeo(attribute: { eq: "keywords" }) {
              value
          }
          site {
            siteMetadata {
              author
            }
          }
      }
    `
  );

  const metaDescription = ogDescription.value || description;
  const metaTitle = ogTitle.value || title;
  
  let metaKeywords = [];
  if(seoKeywords.value) {
    metaKeywords = seoKeywords.value.split(', ');
  } else {
    metaKeywords = keywords;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${metaTitle}`}
      meta={[
        {
          name: `description`,
          content: defaultMetaDescription.value,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaKeywords.length > 0
            ? {
                name: `keywords`,
                content: metaKeywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

export default SEO
